import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const Page404 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Page404')));
const Success = Loadable(lazy(() => import('views/pages/authentication/authentication3/Success')));
const Forgetpassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/Forgetpassword')));
const ResetPassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/Resetpassword')));
const Logout = Loadable(lazy(() => import('views/pages/Logout')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //
const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/',
            element: <AuthLogin3 />
        },
        {
            path: '/login',
            element: <AuthLogin3 />
        },
        {
            path: '/register',
            element: <AuthRegister3 />
        },
        {
            path: '/forget-password',
            element: <Forgetpassword />
        },
        {
            path: '/reset-password/:token',
            element: <ResetPassword />
        },
        {
            path: '/success/:id',
            element: <Success />
        },
        {
            path: '/logout',
            element: <Logout />
        },
        {
            path: '*',
            element: <Page404 />
        }
    ]
};

export default AuthenticationRoutes;
