import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    let token =sessionStorage.getItem('token');
    let route=(token)?MainRoutes:AuthenticationRoutes;
    return useRoutes([route]);
}
