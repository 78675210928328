import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const Page404 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Page404')));
const Userprofile = Loadable(lazy(() => import('views/pages/profile/Userprofile')));
const Customerrequest = Loadable(lazy(() => import('views/pages/customerrequest/customerrequest')));
const Viewrequest = Loadable(lazy(() => import('views/pages/customerrequest/viewrequest')));

const Myorder = Loadable(lazy(() => import('views/pages/order/myorder')));
const Myoffer = Loadable(lazy(() => import('views/pages/customerrequest/myoffer')));

const Mycontract = Loadable(lazy(() => import('views/pages/contract/contract')));
const Createcontract = Loadable(lazy(() => import('views/pages/contract/createcontract')));

const PScontract = Loadable(lazy(() => import('views/pages/contract/pscontract')));

const Logout = Loadable(lazy(() => import('views/pages/Logout')));
const Vieworder = Loadable(lazy(() => import('views/pages/order/vieworder')));
const Customerassociate = Loadable(lazy(() => import('views/pages/Mycustomer')));
const Businessassociate = Loadable(lazy(() => import('views/pages/Mypartner')));
const Learning = Loadable(lazy(() => import('views/pages/Learning')));
const SurveyForm = Loadable(lazy(() => import('views/pages/SurveyForm')));


// ==============================|| MAIN ROUTING ||============================== //
const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: '/dashboard',
            children: [
                {
                    path: '/dashboard',
                    element: <DashboardDefault />
                }
            ]
        },
        {
            path: '/survey-form',
            children: [
                {
                    path: '/survey-form/:id',
                    element: <SurveyForm />
                }
            ]
        },
        {
            path: 'user-profile',
            children: [
                {
                    path: '/user-profile',
                    element: <Userprofile />
                }
            ]
        },
        {
            path: '/my-offer',
            children: [
                {
                    path: '/my-offer',
                    element: <Myoffer />
                }
            ]
        },
        {
            path: '/customer-request',
            children: [
                {
                    path: '/customer-request',
                    element: <Customerrequest />
                }
            ]
        },
        {
            path: '/learning',
            children: [
                {
                    path: '/learning',
                    element: <Learning />
                }
            ]
        },
        {
            path: '/view-request/',
            children: [
                {
                    path: '/view-request/:id',
                    element: <Viewrequest />
                }
            ]
        },
        {
            path: '/my-order',
            children: [
                {
                    path: '/my-order',
                    element: <Myorder />
                }
            ]
        },
        {
            path: '/view-order',
            children: [
                {
                    path: '/view-order/:id',
                    element: <Vieworder />
                }
            ]
        },
      
        {
            path: '/my-contract',
            children: [
                {
                    path: '/my-contract',
                    element: <Mycontract />
                }
            ]
        },
        {
            path: '/ps-contract',
            children: [
                {
                    path: '/ps-contract',
                    element: <PScontract />
                }
            ]
        },
        {
            path: '/create-contract',
            children: [
                {
                    path: '/create-contract',
                    element: <Createcontract />
                }
            ]
        },
        {
            path: '/my-clients',
            children: [
                {
                    path: '/my-clients',
                    element: <Customerassociate />
                }
            ]
        },
        {
            path: '/my-business-associate',
            children: [
                {
                    path: '/my-business-associate',
                    element: <Businessassociate />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'tabler-icons',
                    element: <UtilsTablerIcons />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'material-icons',
                    element: <UtilsMaterialIcons />
                }
            ]
        },
        {
            path: 'sample-page',
            element: <SamplePage />
        },
        {
            path: '*',
            element: <Page404 />
        },
        {
            path: '/logout',
            element: <Logout />
        },
    ]
};

export default MainRoutes;
