// assets
import { IconBrandChrome, IconHelp } from '@tabler/icons';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HelpIcon from '@mui/icons-material/Help';
import GroupIcon from '@mui/icons-material/Group';

import RequestQuoteRoundedIcon from '@mui/icons-material/RequestQuoteRounded';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import OndemandVideoRoundedIcon from '@mui/icons-material/OndemandVideoRounded';
const icons = { IconBrandChrome, IconHelp };
// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //
const other = {
    id: 'sample-docs-roadmap',
    type: 'group',
    children: [
        {
            id: 'Social Profile',
            title: 'Social Profile',
            type: 'item',
            url: '/user-profile',
            icon: AccountBoxIcon,
            breadcrumbs: false
        },
        {
            id: 'Customer Request',
            title: 'Customer Request',
            type: 'item',
            url: '/customer-request',
            icon: RequestQuoteRoundedIcon,
            breadcrumbs: false
        },
        {
            id: 'Learning',
            title: 'Learning',
            type: 'item',
            url: '/learning',
            icon: OndemandVideoRoundedIcon,
            breadcrumbs: false
        },
        {
            id: 'My Offer',
            title: 'My Offer',
            type: 'item',
            url: '/my-offer',
            icon: AccountTreeIcon,
            breadcrumbs: false
        },
        {
            id: 'My Order',
            title: 'My Order',
            type: 'item',
            url: '/my-order',
            icon: LocalOfferIcon,
            breadcrumbs: false
        },
      
        {
            id: 'My Contract',
            title: 'Contract',
            type: 'collapse',
            icon: ContactEmergencyIcon,
            children: [
                {
                    id: 'tabler-icons',
                    title: 'My Contact',
                    type: 'item',
                    url: '/my-contract',
                    breadcrumbs: false
                },
                {
                    id: 'material-icons',
                    title: 'PS Contract',
                    type: 'item',
                    url: '/ps-contract',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'My Team',
            title: 'My Team',
            type: 'collapse',
            icon: GroupIcon,
            children: [
                {
                    id: 'tabler-icons',
                    title: 'Clients',
                    type: 'item',
                    url: '/my-clients',
                    breadcrumbs: false
                },
                {
                    id: 'material-icons',
                    title: 'Business Associates',
                    type: 'item',
                    url: '/my-business-associate',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'website',
            title: 'Professionalsaathi',
            type: 'item',
            url: sessionStorage.getItem('slugurl'),
            icon: HelpIcon,
            external: true,
            target: true
        }
    ]
};

export default other;
